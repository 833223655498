<template>
  <!-- Grids in modals -->
  <b-modal v-model="showModal" hide-footer title="Import smgses from XLSX" class="v-modal-custom" centered>
    <form action="/" method="POST" @submit.prevent="submit">
      <div class="modal-body">
        <div class="mb-3">
          <label for="xlsxfile" class="form-label">XLSX</label>
          <input id="xlsxfile" class="form-control" type="file"
                 accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
        </div>
      </div>
      <div class="modal-footer hstack gap-2 justify-content-end">
        <b-button type="button" variant="light" @click="showModal = false">
          Close
        </b-button>
        <b-button type="submit" variant="success">Submit</b-button>
      </div>
    </form>
  </b-modal>
  <b-modal v-model="showXLSXModal" hide-footer
           :title="'Upload existing XLSX for '+ getSlicedDocumentName(selected_doc ? selected_doc.name : '')+'.'"
           class="v-modal-custom" centered>
    <form action="/" method="POST" @submit.prevent="uploadDocXLSX">
      <div class="modal-body">
        <div class="mb-3">
          <label for="oldxlsxfile" class="form-label">XLSX</label>
          <input id="oldxlsxfile" class="form-control" type="file"
                 accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
        </div>
      </div>
      <div class="modal-footer hstack gap-2 justify-content-end">
        <b-button type="button" variant="light" @click="showXLSXModal = false">
          Close
        </b-button>
        <b-button type="submit" variant="success">Upload</b-button>
      </div>
    </form>
  </b-modal>
  <PageHeader title="SMGS" :items="items"/>
  <div class="container-fluid">
    <div class="card">
      <div class="card-header p-2">
        <div class="row">
          <div class="col-md-auto ms-auto">
            <b-button class="p-2 m-1" variant="success" v-if="old_xlsx" @click="downloadOldXLSX">Download Old XLSX
            </b-button>
            <b-button class="p-2 m-1" variant="primary" v-if="!old_xlsx" @click="showXLSXModal = !showXLSXModal">
              Upload Old XLSX
            </b-button>
            <b-button class="p-2 m-1" variant="success" @click="downloadXLSX">Download XLSX</b-button>
            <b-button class="p-2 m-1" variant="primary" @click="showModal = !showModal">Upload XLSX</b-button>
            <b-button variant="soft-info"
                      :disabled="!is_confirm"
                      @click="confirm">Confirm
            </b-button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-xxl-6 ps-2" v-if="!current_image.endsWith('.jpg')">
            <!-- <div class="image-container"> -->
              <button v-if="selected_smgs.index > 0" class="prev-button" @click="getPrev(selected_smgs.index)">
                &#10094;
              </button>
              <!-- <img :src="current_image" class="image" @click="scanDoc()"/> -->
              <!-- <DocPreviewModal :file_url="current_image"/> -->
              <iframe :src="current_image" width="100%" height="1200px"></iframe>
              <button v-if="selected_smgs.index < visible_rows.length-1" class="next-button"
                      @click="getNext(selected_smgs.index)">&#10095;
              </button> 

            </div>
            <div class="col-xxl-6 ps-2" v-else>
            <div class="image-container">
              <button v-if="selected_smgs.index > 0" class="prev-button" @click="getPrev(selected_smgs.index)">
                &#10094;
              </button>
              <img :src="current_image" class="image" @click="scanDoc()"/>
              <button v-if="selected_smgs.index < visible_rows.length-1" class="next-button"
                      @click="getNext(selected_smgs.index)">&#10095;
              </button>
            </div>
          </div>
          <div class="col-xxl-6 ps-3">
            <h5>Document: {{ selected_doc.name.slice(selected_doc.name.indexOf('_') + 1) }}</h5>
            <h5>Selected Page Number: {{ selected_smgs.index + 1 + '/' + selected_doc.smgses.length }}
              {{ missing_smgses.length > 0 ? `, Missing info on page: ${missing_smgses}` : '' }}</h5>
            <!-- <b-button variant="soft-info" @click="confirm">Confirm</b-button> -->
            <b-alert :show="is_confirm" dismissible variant="success" class="alert-border-left fade show" role="alert">
              <i class="ri-user-smile-line me-1 align-large"></i>
              Existing Application Code Matching: <b>{{ match ? match.exist : 0 }}/{{ visible_rows.length }}</b>
              Not Found Codes: <b>{{ match ? match.not_exist : [] }}</b>
            </b-alert>
            <b-alert :show="show_alert" dismissible variant="success" class="alert-border-left fade show" role="alert">
              <i class="ri-user-smile-line me-1 align-large"></i>
              Extracted Code: <b>{{ scan ? scan.code : "--" }}
            </b> Smgs: <b>{{
                scan.smgs_number ? scan.smgs_number : "--"
              }}</b> Container: <b>{{ scan.container ? scan.container : "--" }}</b>
            </b-alert>
            <form @submit.prevent="updateSmgs">
              <div class="modal-body">
                <div class="row">
                  <div class="col-xxl-4 ps-0">
                    <div class="mb-3">
                      <input class="form-control"
                             placeholder="SMGS Number" required v-model="selected_smgs.number"/>
                    </div>
                  </div>
                  <div class="col-xxl-4 ps-0">
                    <div class="mb-3">
                      <input class="form-control"
                             placeholder="Weight" required v-model="selected_smgs.weight"/>
                    </div>
                  </div>
                  <div class="col-xxl-4 ps-0">
                    <div class="mb-3">
                      <flat-pickr
                          v-model="selected_smgs.date" placeholder="Current date"
                          class="form-control">
                      </flat-pickr>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xxl-6 ps-0">
                    <div class="mb-3">
                      <Multiselect
                          v-model="wagon.selected"
                          :searchable="true"
                          :closeOnSelect="true"
                          :options="wagon.options"
                          placeholder="Wagon"
                          @input="selectWagon($event)"
                          @search-change="getWagonOrContainerOptions($event, 'wagons')"
                          :object="true"
                      />
                    </div>
                  </div>
                  <div class="col-xxl-6 ps-0">
                    <div class="mb-3">
                      <Multiselect
                          v-model="container.selected"
                          :searchable="true"
                          :closeOnSelect="true"
                          :options="container.options"
                          placeholder="Container"
                          @input="selectContainer($event,'do')"
                          @search-change="getWagonOrContainerOptions($event, 'containers')"
                          :object="true"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xxl-6 ps-0">
                    <div class="mb-3">
                      <Multiselect
                          v-model="departure.selected"
                          :searchable="true"
                          :closeOnSelect="true"
                          :options="departure.options"
                          placeholder="Departure"
                          @search-change="getStationOptions($event, 'departure')"
                          :object="true"
                          required
                      />
                    </div>
                  </div>
                  <div class="col-xxl-6 ps-0">
                    <div class="mb-3">
                      <Multiselect
                          v-model="destination.selected"
                          :searchable="true"
                          :closeOnSelect="true"
                          :options="destination.options"
                          placeholder="Destination"
                          @search-change="getStationOptions($event, 'destination')"
                          :object="true"
                          required
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xxl-6 ps-0">
                    <div class="mb-3">
                      <Multiselect
                          v-model="shipper.selected"
                          :searchable="true"
                          :closeOnSelect="true"
                          :options="shipper.options"
                          placeholder="Shipper"
                          @search-change="getThirdPartyOptions($event, 'shippers')"
                          :object="true"
                      />
                    </div>
                  </div>
                  <div class="col-xxl-6 ps-0">
                    <div class="mb-3">
                      <Multiselect
                          v-model="consignee.selected"
                          :searchable="true"
                          :closeOnSelect="true"
                          :options="consignee.options"
                          placeholder="Consignee"
                          @search-change="getThirdPartyOptions($event, 'consignees')"
                          :object="true"
                      />
                    </div>
                  </div>
                </div>
                <div class="row" v-for="(code, index) in selected_smgs.smgs_codes" :key="index">
                  <div class="col-xxl-4 ps-0">
                    <div class="mb-3">
                      <Multiselect
                          v-model="selected_smgs.smgs_codes[index].forwarder"
                          :searchable="true"
                          :closeOnSelect="true"
                          :options="forwarder.options"
                          placeholder="Forwarder"
                          @search-change="getCounterpartyOptions($event)"
                          :object="true"
                      />
                    </div>
                  </div>
                  <div class="col-xxl-4 ps-0">
                    <div class="mb-3">
                      <input class="form-control"
                            placeholder="Code"
                            v-model="selected_smgs.smgs_codes[index].code"
                            @input="ValidateSmgsCode(selected_smgs.smgs_codes[index].code)"
                            />
                    </div>
                  </div>
                  <div class="col-xxl-4 ps-0 mb-2">
                    <div class="input-group">
                      <input class="form-control" type="number"
                             step="any"
                             placeholder="Cost" v-model="selected_smgs.smgs_codes[index].cost"/>
                      <font-awesome-icon
                          icon="fa-solid fa-plus"
                          class="c_icon m-2 c_icon_hoverable text-primary"
                          @click="addCode()"
                      />
                      <font-awesome-icon @click="deleteCodeRow(index)" icon="fa-solid fa-trash"
                                         class="c_icon m-2 c_icon_hoverable text-danger"/>
                    </div>
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-xxl-6 ps-0">
                    <div class="mb-3">
                      <input type="number" class="form-control"
                             placeholder="Weight" required v-model="selected_smgs.weight"/>
                    </div>
                  </div>
                  <div class="col-xxl-6 ps-0">
                    <div class="mb-3">
                      <Multiselect
                          class="ms-0 form-label"
                          v-model="forwarder"
                          :searchable="true"
                          :closeOnSelect="true"
                          :options="forwarder_options"
                          placeholder="Forwarder"
                          :object="true"
                          required
                      />
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="hstack gap-2 mb-4">
                <button type="submit" class="btn btn-success" style="width: 100%">
                  Add info
                </button>
              </div>
            </form>
            <div class="table-responsive table-card" style="height: 600px; overflow-y: auto;">
              <table class="table align-middle position-relative table-nowrap">
                <thead class="table-active">
                <tr>
                  <th scope="col">№</th>
                  <th scope="col">SMGS №</th>
                  <th scope="col">Date</th>
                  <th scope="col">Wagon</th>
                  <th scope="col">Container</th>
                  <th scope="col">Code №</th>
                  <th scope="col">Weight</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="row in visible_rows" :key="row.id"
                    @click="onRowClick(row.index)"
                    :class="row.index == selected_smgs.index ? 'text-success border-success': ''">
                  <th scope="row">
                    {{ row.index + 1 }}
                  </th>
                  <td style="white-space: pre-wrap;" class="text-start">
                    {{ row.number ? row.number : '--' }}
                  </td>
                  <td>{{ row.date ? row.date : '--' }}</td>
                  <td>{{ row.wagon_name ? row.wagon_name : '--' }}</td>
                  <td>{{ row.container_name ? row.container_name : '--' }}</td>
                  <td>{{ getCodes(row.smgs_codes).join(', ') }}</td>
                  <td>{{ row.weight ? row.weight : '--' }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <b-button
                @click="generateExcel(getSlicedDocumentName(selected_doc ? selected_doc.name : '').split('.pdf')[0])"
                variant="success" class="mt-4 w-100">
              Download Excel for {{ getSlicedDocumentName(selected_doc ? selected_doc.name : '').split('.pdf')[0] }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import Swal from "sweetalert2";
import PageHeader from "../../../components/page-header.vue";
import OrderService from "@/api";
import CoreApi from "@/api/core/core";
import 'vue-select/dist/vue-select.css';
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import {saveAs} from 'file-saver';



import ExcelJS from 'exceljs';
import axios from "axios";


export default {
  name: "ProductsList",
  data() {
    return {
      destination: {
        selected: null,
        options: []
      },
      departure: {
        selected: null,
        options: []
      },
      wagon: {
        selected: null,
        options: []
      },
      container: {
        selected: null,
        options: []
      },
      consignee: {
        selected: null,
        options: []
      },
      shipper: {
        selected: null,
        options: []
      },
      forwarder: {
        selected: null,
        options: []
      },
      show_alert: false,
      current_image: '',
      visible_rows: [],
      selected_smgs: {smgs_codes: []},
      missing_smgses: [],
      selected_doc: {
        name: '',
        smgses: [],
      },
      coreApi: new CoreApi(),
      toast_alert: this.getAlert(),
      base_url: `${process.env.VUE_APP_ORDER_URL}/`,
      old_xlsx: null,
      showModal: false,
      showXLSXModal: false,
      scan: {},
      match: {
        match: false,
        exist: [],
        not_exist: [],
      },
      is_confirm: false,
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "SMGS",
          href: "/smgs/extractor/",
        },
      ]
    }
  },
  components: {
    flatPickr,
    PageHeader,
    Multiselect,
  },
  created() {
    this.getDoc(this.$route.params.id)
  },
  methods: {
    setCurrentDocument(url) {
      if (url.endsWith(".pdf")) {
        axios.get(url, {
            responseType: 'arraybuffer'
        }).then(response => {
          console.log(response)
            const blob = new Blob([response.data], {type: 'application/pdf'});
            this.current_image = URL.createObjectURL(blob);
            return this.current_image
        }).catch(error => {
          console.log(error)
            console.log("error", error)
        });
      } 
      // if it is not pdf, return the url
      this.current_image = url
      return url
    },
    getSlicedDocumentName(name) {
      return name ? name.slice(name.indexOf('_') + 1) : name
    },
    deleteCodeRow(index) {
      this.selected_smgs.smgs_codes.splice(index, 1)
    },
    addCode(forwarder) {
      this.selected_smgs.smgs_codes.push({
        code: null,
        forwarder: forwarder ? forwarder : null,
        cost: 0,
      })
    },
    getCodes(arr) {
      let res = []
      if (arr && arr.length > 0) {
        arr.forEach((el) => {
          if (el.code) {
            res.push(el.code)
          }
        })
      }
      return res
    },
    getPrev(index) {
      index--
      this.onRowClick(index)
      this.scan = {}
    },
    getNext(index) {
      index++
      this.onRowClick(index)
      this.scan = {}
    },
    getAlert() {
      return Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
    },
    async selectedFile() {
      const formData = new FormData();
      formData.append("file", document.querySelector(".dropzoneFile").files[0]);
      await OrderService({
        url: '/smgs/pdf/',
        method: 'post',
        data: formData,
        headers: {"Content-Type": "multipart/form-data"},
      }).then((res) => {
        this.selected_doc = res.data
        this.selected_smgs = this.selected_doc.smgses.length > 0 ? this.selected_doc.smgses[0] : {}
        this.current_image = this.setCurrentDocument(this.base_url + this.selected_smgs.image_path)
        this.toast_alert.fire({
          icon: 'success',
          title: 'File upload was successful!'
        })
      }).catch((err) => {
        this.toast_alert.fire({
          icon: 'error',
          title: err
        })
      })
    },
    async uploadDocXLSX() {
      const formData = new FormData();
      formData.append('file', document.getElementById('oldxlsxfile').files[0]);
      await OrderService({
        url: `/smgs/upload/${this.selected_doc.id}/`,
        method: 'post',
        data: formData,
        headers: {"Content-Type": "multipart/form-data"},
      }).then(() => {
        Swal.fire("XLXS has been uploaded!", "", "success");
      }).catch((err) => {
        Swal.fire("Sorry!", `${err}`, "error");
      })
      await this.getDoc(this.selected_doc.id)
      this.showXLSXModal = !this.showXLSXModal
    },
    async downloadOldXLSX() {
      window.open(`${process.env.VUE_APP_ORDER_URL + this.selected_doc.xlsx}`, '_blank');
    },
    async submit() {
      const formData = new FormData();
      formData.append('file', document.getElementById('xlsxfile').files[0]);
      await OrderService({
        url: `/smgs/xlsx/process/`,
        method: 'post',
        data: formData,
        headers: {"Content-Type": "multipart/form-data"},
      }).then(() => {
        Swal.fire("Smgses Updated!", "", "success");
      }).catch((err) => {
        Swal.fire("Sorry!", `${err}`, "error");
      })
      await this.getDoc(this.selected_doc.id)
      this.showModal = !this.showModal
    },
    async scanDoc() {
      let image_path = this.selected_smgs.image_path.split("/").slice(3).join("/");
      let result = await OrderService({
        url: `/smgs/image/${image_path}/`,
      })
      result = result.data
      if (result.code || result.smgs_number || result.container) {
        this.show_alert = true
        this.scan = result
        if (result.container) {
          this.getWagonOrContainerOptions(result.container, 'containers')
        }
        if (result.smgs_number) {
          this.selected_smgs.number = result.smgs_number
        }
      }
    },
    async getCounterpartyOptions(query) {
      if (query.length < 2) {
        return
      }
      let forwarders = (await this.coreApi.getCounterpartiesForCode(query, 4, 0)).results
      this.forwarder.selected = null
      this.forwarder.options = forwarders.map((el) => {
        return {value: el.id, label: el.name}
      })
    },
    async downloadXLSX() {
      window.open(`${process.env.VUE_APP_ORDER_URL}/smgs/xlsx/download/${this.selected_doc.id}/`, '_blank');
    },
    async confirm() {
      // TODO: Refactor
      const smgs_code_ids = []
      const forwarder_ids = []
      const code_numbers = []
      const code_smgs_obj = []
      //  TODO: O(n^2) -> O(n)
      this.visible_rows.forEach(obj => {
        if (obj.smgs_codes.length > 0) {
          obj.smgs_codes.forEach(codes => {
            if (codes.code) {
              forwarder_ids.push(codes.forwarder_id)
              smgs_code_ids.push(codes.id)
              code_numbers.push(codes.code)
              code_smgs_obj.push({smgs_code_id: codes.id, code: codes.code})
            }
          })
        }
        return
      });
      await OrderService({
        url: `/smgs/sync/code/`,
        method: 'PUT',
        data: {
          forwarder_ids: forwarder_ids,
          codes: code_numbers,
          smgs_code_ids: smgs_code_ids,
          code_smgs: code_smgs_obj,
        }
      }).then(() => {
        this.toast_alert.fire({
          icon: 'success',
          title: 'All codes have been updated!'
        })
      }).catch((err) => {
        this.toast_alert.fire({
          icon: 'error',
          title: err
        })
      })
    },
    async checkCode() {
      const sc = []
      this.visible_rows.forEach((el) => {
        const codes = el.smgs_codes
        codes.forEach((el) => {
          sc.push(el)
        })
      })
      await OrderService({
        url: `/smgs/check/code/`,
        method: 'POST',
        data: {
          codes: sc,
        }
      }).then((res) => {
        this.match = res.data
      }).catch((err) => {
        console.log(err)
      })
    },
    async getDoc(id) {
      try {
        let res = await axios.get(`/smgs/document/by/${id}/`)
        this.selected_doc = res.data
        this.old_xlsx = this.selected_doc.xlsx
        this.visible_rows = this.selected_doc.smgses
        let infos = new Array(this.visible_rows.length).fill(0)
        this.visible_rows.forEach((el) => {
          infos[el.index] = 1
        })
        infos.forEach((value, index) => {
          if (value === 0) {
            this.missing_smgses.push(index);
          }
        });
        if (this.visible_rows.length == this.selected_doc.smgses.length) {
          this.is_confirm = this.visible_rows.length === this.selected_doc.smgses.length
          if (this.is_confirm) {
            this.checkCode()
          }
          return this.onRowClick(this.visible_rows.length - 1)
        }
        return this.onRowClick(this.visible_rows.length)
      } catch {
        alert("Cannot open this page !")
      }
    },
    async onRowClick(index) {
      const row = this.selected_doc.smgses[index];
      if (row.departure_station) {
        this.departure.selected = {value: row.departure_station.id, label: row.departure_station.name}
      }
      if (row.destination_station) {
        this.destination.selected = {value: row.destination_station.id, label: row.destination_station.name}
      }
      if (row.wagon_id) {
        this.wagon.selected = {value: row.wagon_id, label: row.wagon_name}
      }
      if (row.container_id) {
        this.container.selected = {value: row.container_id, label: row.container_name}
      }
      if (row.shipper_id) {
        this.shipper.selected = {value: row.shipper_id, label: row.shipper_name}
      }
      if (row.consignee_id) {
        this.consignee.selected = {value: row.consignee_id, label: row.consignee_name}
      }

      if (row.smgs_codes.length > 0) {
        row.smgs_codes = row.smgs_codes.map((code) => {
          return code.forwarder
              ? {...code, forwarder: {value: code.forwarder_id, label: code.forwarder_name}}
              : code;
        });


      }
      this.selected_smgs = row;
      this.current_image = this.setCurrentDocument(this.base_url + this.selected_smgs.image_path)
      if (this.selected_smgs.smgs_codes.length == 0) {
        this.addCode();
      }
    },

    setSelectedSmgs() {
      this.selected_smgs.departure_station_id = this.departure.selected ? this.departure.selected.value : null;
      this.selected_smgs.destination_station_id = this.destination.selected ? this.destination.selected.value : null;
      this.selected_smgs.wagon_id = this.wagon.selected ? this.wagon.selected.value : null;
      this.selected_smgs.container_id = this.container.selected ? this.container.selected.value : null;
      this.selected_smgs.consignee_id = this.consignee.selected ? this.consignee.selected.value : null;
      this.selected_smgs.shipper_id = this.shipper.selected ? this.shipper.selected.value : null;
    },

    checkSmgsCodes() {
      this.selected_smgs.smgs_codes.forEach((el) => {
        if (!el.id) {
          el.id = 0
        }
        if (el.forwarder) {
          el.forwarder_id = el.forwarder ? el.forwarder.value : null;
        } else {
          this.toast_alert.fire({
            icon: 'error',
            title: 'Please, select forwarder on smgs code!',
          });
          return;
        }
      });
    },

    async updateSmgs() {
      const headers = new Headers();
      headers.append("Content-Type", `application/json`);

      this.checkSmgsCodes();
      this.setSelectedSmgs();

      try {
        const res = await OrderService({
          url: `/smgs/update/${this.selected_smgs.id}/`,
          method: 'PUT',
          data: this.selected_smgs,
          headers: headers
        });
        let foundIndex = this.visible_rows.findIndex(x => x.index == res.data.index);
        this.visible_rows[foundIndex] = res.data
        const prev_number = this.selected_smgs?.number
        const prev_date = this.selected_smgs?.date
        const prev_forwarder = this.selected_smgs.forwarder
        this.selected_smgs = this.selected_doc.smgses.find(el => el.index == this.selected_smgs.index + 1)

        this.selected_smgs.number = this.selected_smgs.number
            ? this.selected_smgs.number : prev_number
        this.selected_smgs.date = this.selected_smgs.date ? this.selected_smgs.date : prev_date

        this.container.selected = this.selected_smgs.container_id ? this.getAutocompleteModel(this.selected_smgs.container_id, this.selected_smgs.container_name) : null
        this.wagon.selected = this.selected_smgs.wagon_id ? this.getAutocompleteModel(this.selected_smgs.wagon_id, this.selected_smgs.wagon_name) : null
        this.shipper.selected = this.selected_smgs.shipper_id ? this.getAutocompleteModel(this.selected_smgs.shipper_id, this.selected_smgs.shipper_name) : null
        this.consignee.selected = this.selected_smgs.consignee_id ? this.getAutocompleteModel(this.selected_smgs.consignee_id, this.selected_smgs.consignee_name) : null

        if (this.selected_smgs.smgs_codes.length === 0) {
          this.addCode(prev_forwarder)
        }
      } catch (err) {
        alert(err);
      }
      this.current_image = this.setCurrentDocument(this.base_url + this.selected_smgs.image_path)
      this.is_confirm = this.visible_rows.length === this.selected_doc.smgses.length;
    },
    getAutocompleteModel(value, label) {
      return {value: value, label: label}
    },
    async getStationOptions(query, option_type) {
      if (query.length <= 0) return;
      let options = (await this.coreApi.getCoreList('stations', query, 4)).results
      if (options.length > 0) {
        options = options.map((el) => {
          return {value: el.id, label: el.name + ` (${el.code || '0'})`}
        })
      }
      if (option_type === 'departure') {
        this.departure.selected = null
        this.departure.options = options
      } else if (option_type === 'destination') {
        this.destination.selected = null
        this.destination.options = options
      }
    },
    async selectContainer(query) {
      if (query.label.length < 10 || query.value !== 0) {
        return
      } else {
        this.toast_alert.fire({
          icon: 'error',
          title: 'Please, enter valid container, pls check once again!',
        })
      }
      const regex = /^[A-Z]{4}\d{7}$/;
      if (regex.test(query.label) && !(this.container.selected && this.container.selected.value)) {
        await OrderService({
          url: `/core/containers/create/`,
          method: 'POST',
          data: {
            name: query.label,
          }
        }).then((res) => {
          this.container.selected = {value: res.data.id, label: res.data.name}
          this.toast_alert.fire({
            icon: 'success',
            title: 'New Container was created successfully!'
          })
        }).catch((err) => {
          console.log(err)
        })
      } else {
        this.toast_alert.fire({
          icon: 'error',
          title: 'Please, enter valid container, pls check once again!',
        })
      }
    },
    async selectWagon(query) {
      if (query && (query.label.length < 7 || query.value !== 0)) return;
      await OrderService({
        url: `/core/wagons/create/`,
        method: 'POST',
        data: {
          name: query.label,
        }
      }).then((res) => {
        if (res.data) {
          this.toast_alert.fire({
            icon: 'success',
            title: 'New Wagon was created successfully!'
          })
          this.wagon.selected = {value: res.data.id, label: res.data.name}
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    async getWagonOrContainerOptions(query, option_type) {
      if (query.length <= 2) return;
      let options = (await this.coreApi.getContainerWagonList(option_type, query, 4)).results
      if (options.length > 0) {
        options = options.map((el) => {
          return {value: el.id, label: el.name}
        })
      } else if (options.length === 0 && query.length > 7) {
        options.push({label: query, value: 0})
      }
      if (option_type === 'containers') {
        this.container.options = options
        this.container.selected = this.container.options.length == 1 ? options[0] : null
      } else if (option_type === 'wagons') {
        this.wagon.options = options
        this.wagon.selected = this.wagon.options.length == 1 ? options[0] : null
      }
    },
    async getCodeForwarder(forwarder_id, forwarder_name) {
      return {value: forwarder_id, label: forwarder_name}
    },
    async ValidateSmgsCode(inputCode) {
      if (inputCode.length > 4) {
        // throw button error or alert saying it is already exists
      // await OrderService({url: `/smgs/list/code=${inputCode}&document=${this.selected_doc.name}`})
      // if the data > 0 
        // exists
      }
    },
    async ValidateSmgsContainer(inputCode) {
      if (inputCode.length > 9) {
        // throw button error or alert saying it is already exists
      // await OrderService({url: `/smgs/list/code=${inputCode}&document=${this.selected_doc.name}`})
      // if the data > 0 
        // exists
      }
    },
    async getThirdPartyOptions(query, option_type) {
      if (query.length <= 1) return;
      await OrderService({url: `/customer/companies/?search=${query}`})
          .then(res => {
            let options = res.data.results
            if (options.length > 0) {
              options = options.map((el) => {
                return {value: el.id, label: el.name}
              })
            }
            if (option_type === 'consignees') {
              this.consignee.selected = null
              this.consignee.options = options
            } else if (option_type === 'shippers') {
              this.shipper.selected = null
              this.shipper.options = options
            }

          }).catch((err) => {
            console.log(err)
          })
    },
    async generateExcel(book_name) {
      let bookName = book_name.replace(/\s/g, "-") || 'data';

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(bookName);

      worksheet.columns = [
        {header: 'SMGS number', key: 'number', width: 20},
        {header: 'Date', key: 'date', width: 15},
        {header: 'Departure', key: 'departure_station_name', width: 25},
        {header: 'Destination', key: 'destination_station_name', width: 25},
        {header: 'Container', key: 'container_name', width: 25},
        {header: 'Wagon', key: 'wagon_name', width: 20},
        {header: 'Codes', key: 'codes', width: 20},
      ];

      this.visible_rows.forEach((data) => {
        worksheet.addRow({
          number: data.number,
          date: data.date,
          departure_station_name: data.departure_station_name,
          destination_station_name: data.destination_station_name,
          container_name: data.container_name,
          wagon_name: data.wagon_name,
          codes: this.getCodes(data.smgs_codes).join(', '),
        });
      });

      // Apply style to header row
      const headerRow = worksheet.getRow(1);
      headerRow.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: '#acc7f5'},
        };
        cell.font = {
          bold: true,
        };
        cell.alignment = {vertical: 'middle', horizontal: 'center'};
      });


      worksheet.eachRow({includeEmpty: true}, function (row) {
        row.eachCell({includeEmpty: true}, function (cell) {
          cell.alignment = {vertical: 'middle', horizontal: 'center'};
          cell.border = {
            top: {style: 'thin'},
            left: {style: 'thin'},
            bottom: {style: 'thin'},
            right: {style: 'thin'},
          };
        });
      });

      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buffer], {type: 'application/octet-stream'}), `${bookName}.xlsx`);
    },
  },
}
</script>

<style scoped>
:root {
  /* --vs-search-input-placeholder-color: inherit; */
  --vs-search-input-color: inherit;
  --vs-search-input-bg: rgb(255, 0, 0);
  --vs-search-input-placeholder-color: inherit;

}

.image-container {
  position: relative;
  width: 100%;
  height: auto;
}

.image-container .image {
  width: 100%;
  height: auto;
}

.image-container .image:hover {
  cursor: pointer;
}

.prev-button, .next-button {
  position: absolute;
  top: 50%;
  width: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: translateY(-50%);
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}

/* .image-container {
  width: 100%;
  height: auto;
} */

.btn-container {
  display: inline-block;
  position: relative;
}

.btn-container .card {
  width: 100%;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.pdf-and-table-container {
  display: flex;
}

.prev:hover,
.next:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

/* Caption text */
.text {
  color: #000000;
  font-size: 25px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  top: 0;
  text-align: center;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  /* color: gray; */
  font-weight: bold;
  font-size: 28px;
  transition: 0.6s ease;
}

thead {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.dropzone {
  text-align: center;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

/* Position the "next button" to the right */
.next {
  right: 0;
}

</style>