import axios from 'axios'

const OrderService = axios.create({
  baseURL: process.env.VUE_APP_ORDER_URL,
  timeout: 1000000
})

const authToken = localStorage.getItem('jwt_token');

OrderService.interceptors.request.use(
  function (config) {
    config.headers['Authorization'] = `Bearer ${authToken}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default OrderService